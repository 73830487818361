import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

import { getParamInUrl } from 'utils';
import { setCachedFilters } from 'redux/searches/actions';
import connectArchitectureBuildingBlocks from 'react/business/architectureBuildingBlocks/card/connect/withAllArchitectureBuildingBlocks.connect';
import connectSubsets from 'react/business/subsets/card/connect/withAllSubsets.connect';
import { getProducts } from 'redux/products/actions';
import { defaultFilters } from 'react/business/facets/connect/withFiltersControllerInState';

import CommunitiesPresentation from './filters/communities/Communities.presentation';
import connectCommunities from './filters/communities/communities.connect';
import BusinessUnitsPresentation from './filters/businessUnits/BusinessUnits.presentation';
import BusinessUnitsOwnersPresentation from './filters/businessUnitsOwners/BusinessUnitsOwners.presentation';
import connectBusinessUnits from './filters/businessUnits/businessUnits.connect';
import StagesPresentation from './filters/stages/Stages.presentation';
import connectStages from './filters/stages/stages.connect';
import connectMaintainedByPlatform from './filters/isMaintainedByPlatform/isMaintainedByPlatform.connect';
import BusinessActivitiesPresentation from './filters/businessActivities/BusinessActivities.presentation';
import connectBusinessActivities from './filters/businessActivities/businessActivities.connect';
import ArchitectureBuildingBlocksPresentation from './filters/architectureBuildingBlocks/ArchitectureBuildingBlocks.presentation';
import SubsetsPresentation from './filters/subsets/Subsets.presentation';
import MaintainedByPlatformPresentation from './filters/isMaintainedByPlatform/IsMaintainedByPlatform.presentation';
import ProductSortPresentation from './filters/sort/products/ProductSort.presentation';
import connectProductSort from './filters/sort/products/productSort.connect';

import withAdvancedFiltersDisplaySwitch from './controls/advancedFilters/withAdvancedFiltersDisplaySwitch';
import { FILTERS } from './facets.constants';

const enhancer = compose(
  withAdvancedFiltersDisplaySwitch,
  connect(
    null,
    {
      onGetProducts: getProducts,
      onSetCachedFilters: setCachedFilters,
    },
  ),

  withHandlers({
    // Override the onHideMoreFilters to also reset advanced filters.
    onHideMoreFilters: ({ onResetFilters, onHideMoreFilters }) => () => {
      // Reset advanced filters.
      onResetFilters([
        FILTERS.MACRO_ACTIVITY_IDS,
        FILTERS.ACTIVITY_IDS,
        FILTERS.ATOMIC_ACTIVITY_IDS,
        FILTERS.ARCHITECTURE_BUILDING_BLOCK_IDS,
        FILTERS.ABB_COMMUNITY_IDS,
        FILTERS.SUBSET_IDS,
        FILTERS.BUSINESS_UNIT_OWNER_IDS,
      ]);

      // Hide the filters.
      onHideMoreFilters();
    },
  }),

  memo,
);

const Communities = connectCommunities(CommunitiesPresentation);
const BusinessUnits = connectBusinessUnits(BusinessUnitsPresentation);
const BusinessUnitsOwners = connectBusinessUnits(BusinessUnitsOwnersPresentation);
const Stages = connectStages(StagesPresentation);
const ProductSort = connectProductSort(ProductSortPresentation);
const BusinessActivities = connectBusinessActivities(BusinessActivitiesPresentation);
const ArchitectureBuildingBlocks
  = connectArchitectureBuildingBlocks(ArchitectureBuildingBlocksPresentation);
const Subsets = connectSubsets(SubsetsPresentation);
const MaintainedByPlatform = connectMaintainedByPlatform(MaintainedByPlatformPresentation);

const FacetsContainerProducts = ({
  filters,
  onSetFilters,
  onResetFilters,
  shouldShowMoreFilters,
  onHideMoreFilters,
  onShowMoreFilters,
  children,
  onGetProducts,
  onSetCachedFilters,
}) => {
  const location = useLocation();
  onSetCachedFilters(filters);

  const onSetFiltersAndFetchProducts = async (newFilters) => {
    const q = getParamInUrl(location.search, 'q');

    const updatedFilters = {
      ...filters,
      ...newFilters,
      q,
    };
    onSetFilters(updatedFilters);
    onSetCachedFilters(updatedFilters);
    await onGetProducts(updatedFilters, true);
  };

  const onResetFiltersAndFetchProducts = async () => {
    onResetFilters();
    await onGetProducts(defaultFilters, true);
  };

  /* // To stop useEffect loop
  const cbSetFilters = useCallback(
    f => onSetFilters(f),
    [onSetFilters],
  );

  useEffect(() => {
    cbSetFilters(filters || cachedSearchFiters);
    onSetCachedFilters(filters || cachedSearchFiters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedSearchFiters]); */

  return children({
    commonFilters: [
      <Stages key="Stages" filters={filters} onSetFilters={onSetFiltersAndFetchProducts} />,
      <Communities key="Communities" filters={filters} onSetFilters={onSetFiltersAndFetchProducts} />,
      <BusinessUnits key="BusinessUnits" filters={filters} onSetFilters={onSetFiltersAndFetchProducts} />,
      <MaintainedByPlatform key="MaintainedByPlatform" filters={filters} onSetFilters={onSetFiltersAndFetchProducts} />,
    ],

    advancedFilters: [
      <BusinessUnitsOwners key="BusinessUnitsOwners" filters={filters} onSetFilters={onSetFiltersAndFetchProducts} />,
      <BusinessActivities key="BusinessActivities" filters={filters} onSetFilters={onSetFiltersAndFetchProducts} />,
      <ArchitectureBuildingBlocks key="ArchitectureBuildingBlocks" filters={filters} onSetFilters={onSetFiltersAndFetchProducts} />,
      <Subsets key="Subsets" filters={filters} onSetFilters={onSetFiltersAndFetchProducts} />,
    ],

    sortFilter: <ProductSort filters={filters} onSetFilters={onSetFiltersAndFetchProducts} />,

    onResetFilters: onResetFiltersAndFetchProducts,
    shouldShowMoreFilters,
    onHideMoreFilters,
    onShowMoreFilters,
  });
};

FacetsContainerProducts.propTypes = {
  filters: PropTypes.shape().isRequired,
  onSetFilters: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  shouldShowMoreFilters: PropTypes.bool,
  onHideMoreFilters: PropTypes.func,
  onShowMoreFilters: PropTypes.func,
  children: PropTypes.func.isRequired,
};

FacetsContainerProducts.displayName = 'FacetsContainerProducts';

export default enhancer(FacetsContainerProducts);
